import * as React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { useEffect, useRef } from "react";
import styled from 'styled-components/macro';
import { AuthButton } from './Button';
import { putApi, getApi } from '../../api/api';
import { setCurrentUser } from '../../store/actions';
import { getAuthState } from '../../store/selectors';
import { useSelector, useDispatch } from 'react-redux';
import QuestionIcon from '../../images/question_icon.png';
import { ApiUser } from '../../api/ApiTypes';
import { logInAnonymously } from '../../utils/AnonymousAuthHelper';

interface Props {
    handleAuthSuccess: () => void;
}

type TelegramUser = {
    id:         number;
    first_name: string;
    last_name:  string;
    username:   string;
    photo_url:  string;
    auth_date:  number;
    hash:       string;
};

const TelegramAuthButton: React.FC<Props> = ({ handleAuthSuccess }) => {
    const firebase = useFirebase();
    const dispatch = useDispatch();

    const telegramRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (telegramRef.current) {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://telegram.org/js/telegram-widget.js?22";
        script.setAttribute("data-telegram-login", "goNowhereBot");
        script.setAttribute("data-size", "large");
        script.setAttribute("data-onauth", "onTelegramAuth(user)");
        script.setAttribute("data-request-access", "write");
        telegramRef.current.appendChild(script);
      }
    }, []);

    // Expose the onTelegramAuth function globally
    useEffect(() => {
      (window as any).onTelegramAuth = (user: TelegramUser) => {
        console.log('Telegram Raw Reponse: ', user);
        console.log(
          `Telegram User Logged in as ${user.first_name} ${user.last_name} (${user.id}${
            user.username ? `, @${user.username}` : ""
          })`
        );
        telegramLoginHandler(user)
      };
    }, []);

    const getUser = async (): Promise<ApiUser> => {
      return await getApi('/user/me').then(async (response) => {
          let user = null;
          try{
              user = await response.json();
          } catch(event){
              console.log('User response is not valid json', response);
          }
          return user as ApiUser;
      })
    };

    const telegramLoginHandler = async (user: TelegramUser) => {
      console.log({ telegramUser: user });
      const userToken = JSON.stringify(user);
      const nwrAuthToken = await putApi('/thirdPartyAuth/telegram', { userToken }).then((response) => {
        if(!response.ok) {
          throw new Error('Telegram Auth Failed');
        }
        return response.json().then((result) => result.nwrAuthToken);
      }).catch((e :Error) => {
        console.log('Telegram Auth Failed with Error: ', e);
        return undefined;
      });

      if(!nwrAuthToken) {
        alert('Telegram Auth Failed');
        return;
      }

      firebase.auth().signInWithCustomToken(nwrAuthToken!).then(async (userCredential) => {
        // Signed in
        //var user = userCredential.user;
        const user = await getUser();
        dispatch(setCurrentUser(user));
        handleAuthSuccess();
      });
    }

    return (
     <div ref={telegramRef}></div>
    );

}

const Button = styled(AuthButton)`
    display: flex;
    padding: 0 15px 0;
    font-size: 20px;
    align-items: center;
    &:hover {
        background: transparent;
        color: var(--off-white);
    }
`;

const Text = styled.span`
    padding: 5px 6px 5px 4px;
    margin: 0;
`;

export default TelegramAuthButton;

